import { API_URL, UI_URL } from "../../../common/data/GlobalData.js";
import { GetLocationFromLatLong } from "../controls/GoogleMap.js";
import Cookies from "js-cookie";
import { CFW_CLIENT_COUNTRY, CFW_CURRENT_URL, CFW_DROPDOWN_COUNTRY, CFW_GENERATED_TAGS, PRODUCTCODES_UPUBLISHME, PRODUCTCODES_URTECHCITY, PRODUCTCODES_URTECHPOGO, PRODUCTCODES_URTECHPOS, PRODUCTCODES_URTECHSHOP, Response_CountryData, Response_DynamicOptionsDropDownData, Response_SiteMapUrlData } from "./CFW.js";
import { Encrypt, GetConfig } from "./SFW.js";
import { GetState, GetStateField, SetState, UpdateStateField } from "./StoreToolkit.js";
import { User } from "./User.js";
import { OptionsData } from "../../../common/data/OptionsData.js";
import { useLocation } from "react-router-dom";
import { LanguagesData } from "../../../common/data/LanguagesData.js";
import { DynamicOptionsData } from "../../../common/data/DynamicOptionsData.js";
export const GUID = () => {
  return ["xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 | 0,
      v = c === "x" ? r : r & 0x3 | 0x8;
    return v.toString(16);
  }), "-", Math.round(Date.now() / 1000).toString()].join("");
};
export const uniq = val => {
  if (val && val.length) {
    return [...new Set(val)];
  }
  return val;
};
export const GetRandomInt = max => {
  return Math.floor(Math.random() * max);
};
export const MatchingFieldsOnly = (data, fieldsToMatch) => {
  const objectToFill = {
    ...fieldsToMatch
  };
  Object.keys(objectToFill).forEach(keys => {
    if (data && data[keys]) {
      objectToFill[keys] = data[keys];
    }
  });
  return objectToFill;
};
export const GetDefaultImage = productCode => {
  switch ((productCode ?? "").toUpperCase()) {
    case PRODUCTCODES_URTECHCITY:
      return `${API_URL}file/SiteImages/urtechcity.png`;
    case PRODUCTCODES_URTECHSHOP:
      return `${API_URL}file/SiteImages/urtechshop.png`;
    case PRODUCTCODES_URTECHPOS:
      return `${API_URL}file/SiteImages/urtechpos.png`;
    case PRODUCTCODES_UPUBLISHME:
      return `${API_URL}file/SiteImages/upublishme.png`;
    case PRODUCTCODES_URTECHPOGO:
      return `${API_URL}file/SiteImages/urtechpogo.png`;
    default:
      return `${API_URL}file/SiteImages/jobportal.png`;
  }
};
export const GeneratePageInfo = function (languageCode, productCode, currentUrl, tranList, currentPageInfo) {
  if (currentPageInfo === void 0) {
    currentPageInfo = null;
  }
  let title;
  let description;
  let parsedRoutePath = CleanUrl(currentUrl.split("?")[0]).toLowerCase().split("/");
  const pageTitle = {
    productlist: 24,
    addpoints: [617, 618],
    adsenseearnings: 648,
    clientprofile: 150,
    companyprofile: 30,
    confirmpayment: 502,
    contactus: 31,
    emailblaster: 274,
    employees: 228,
    ourserviceslist: 28,
    maintainpayment: 318,
    messages: 269,
    ourclients: 32,
    ourpeople: 17,
    payments: 317,
    privacypolicy: 13,
    resetpassword: 18,
    signin: 15,
    translationtype: 46,
    updateprofile: 126,
    userguide: 262,
    banners: 280,
    clientlogos: 32,
    dynamicoptions: 116,
    dynamicoptiontype: 115,
    findjobs: 152,
    ourservices: 129,
    products: 41,
    recommenduslist: 136,
    sitemap: 343,
    userstatusrole: 128,
    dailytimerecord: 254,
    education: 149,
    employeetasklog: 230,
    findprofile: 264,
    jobprofile: 145,
    jobsapplied: 155,
    othercredentials: 148,
    portfolios: 150,
    jobpostsskills: 695,
    postjobs: 154,
    tasklog: 230,
    userlanguage: 73,
    userskills: 146,
    viewmycv: 188,
    workexperience: 184,
    lotcart: 491,
    lotpayments: 567,
    lotterygamelist: 549,
    maintainlotdraw: 554,
    maintainlotterydraws: 541,
    expenses: 391,
    inventory: 353,
    items: 355,
    mycart: 491,
    packages: 356,
    pos: 357,
    resellerpackageitems: 484,
    sales: 354,
    suppliers: 352,
    vieworder: 377,
    findcontent: 311,
    maintainallcontents: 316,
    maintaincontents: 291
  };
  switch (productCode.toUpperCase()) {
    case PRODUCTCODES_URTECHCITY:
      title = "238";
      description = 240;
      break;
    case PRODUCTCODES_URTECHSHOP:
      title = "526";
      description = 529;
      break;
    case PRODUCTCODES_URTECHPOS:
      title = "370";
      description = 345;
      break;
    case PRODUCTCODES_UPUBLISHME:
      title = "286";
      description = 638;
      break;
    case PRODUCTCODES_URTECHPOGO:
      title = "535";
      description = 718;
      break;
    default:
      title = "239";
      description = 241;
      break;
  }
  title = parsedRoutePath.find(item => Object.keys(pageTitle).find(titleTran => item[titleTran])) ?? title;
  if (currentUrl) {
    const pageInfo = {
      Title: isNaN(title) ? title : tranList[title],
      Description: isNaN(description) ? description : tranList[description],
      Image: GetDefaultImage(productCode),
      LanguageCode: languageCode,
      ProductCode: productCode.toUpperCase(),
      Url: currentUrl,
      Tags: "",
      Exclude: 0
    };
    if (currentPageInfo) {
      currentPageInfo.Title = currentPageInfo.Title ?? pageInfo.Title;
      currentPageInfo.Description = currentPageInfo.Description ?? pageInfo.Description;
      currentPageInfo.Image = currentPageInfo.Image ?? pageInfo.Image;
      currentPageInfo.LanguageCode = currentPageInfo.LanguageCode ?? pageInfo.LanguageCode;
      currentPageInfo.ProductCode = currentPageInfo.ProductCode ?? pageInfo.ProductCode;
      currentPageInfo.Url = currentPageInfo.Url ?? pageInfo.Url;
      currentPageInfo.Tags = currentPageInfo.Tags ?? pageInfo.Tags;
      return currentPageInfo;
    }
    return pageInfo;
  }
};
export const Omit = (obj, props) => {
  obj = {
    ...obj
  };
  props.filter(prop => obj[prop]).forEach(prop => delete obj[prop]);
  return obj;
};
export const IncrementPageIndex = function (state, isReset) {
  if (isReset === void 0) {
    isReset = false;
  }
  const currentIndex = GetStateField(state, "PageIndex", 0);
  UpdateStateField(state, "PageIndex", isReset ? 0 : currentIndex + 1);
};
export const GenerateNameFromUrl = paramUrl => {
  return paramUrl.substring(paramUrl.lastIndexOf("/") + 1).split(".")[0];
};
export const GenerateJsonLD = (context, type, title, image, description, tags) => {
  return `\{\"@context\": \"${context}\",\"@type\": \"${type.toUpperCase()}\",\"name\": \"${title}\",\"image\": \"${image}\",\"description\": \"${description.substring(description.lastIndexOf("-") + 1)}\",\"tags\": [\"${tags.replace(/, /g, ",").replace(/,/g, '","')}\"]\}`;
};
export const GenerateStateEntry = function (state, params, concatName) {
  if (concatName === void 0) {
    concatName = "";
  }
  if (concatName) {
    concatName = `-${concatName}`;
  }
  return {
    entry: {
      state: `${state}-entry${concatName}`,
      param: Clone(params)
    },
    error: {
      state: `${state}-entry${concatName}-error`
    },
    filter: {
      state: `${state}-filter${concatName}`,
      param: Clone(params)
    },
    list: {
      state: `${state}-list${concatName}`,
      results: null
    },
    refresherState: `${state}-list${concatName}-refresher-${GUID()}`
  };
};
export const GetDefaultAdsenseId = adsIdx => {
  const adsensIds = GetConfig("CompanyAdSlot").split(",");
  return adsensIds[adsIdx];
};
export const GeneratePath = function (languageCode, productCode, val, isShare) {
  if (isShare === void 0) {
    isShare = false;
  }
  let shareQuery = "";
  if (isShare && User.PublisherId()) {
    let publisherId = User.PublisherId();
    let parentPublisherId = User.ParentPublisherId();
    if (!publisherId || publisherId.length !== 10) {
      publisherId = GetDefaultAdsenseId(1);
    }
    if (!parentPublisherId || parentPublisherId.length !== 10) {
      parentPublisherId = GetDefaultAdsenseId(2);
    }
    shareQuery = `${val.includes("?") ? "&" : "?"}shareid=${publisherId}_${parentPublisherId}`;
  }
  return `/${productCode.toLowerCase()}/${languageCode}/${val}${shareQuery}`;
};
export const UniqBy = (arr, predicate) => {
  const cb = typeof predicate === "function" ? predicate : o => o[predicate];
  return [...arr.reduce((map, item) => {
    const key = !item ? item : cb(item);
    map.has(key) || map.set(key, item);
    return map;
  }, new Map()).values()];
};
export const Sort = function (val, columnName, orderBy, isInt) {
  if (orderBy === void 0) {
    orderBy = "asc";
  }
  if (isInt === void 0) {
    isInt = false;
  }
  if (val && val.length) {
    val.sort((a, b) => {
      if (orderBy === "asc") {
        if (!isInt && a[columnName] < b[columnName] || isInt && parseInt(a[columnName]) < parseInt(b[columnName])) {
          return -1;
        }
        if (!isInt && a[columnName] > b[columnName] || isInt && parseInt(a[columnName]) > parseInt(b[columnName])) {
          return 1;
        }
        return 0;
      }
      if (!isInt && a[columnName] > b[columnName] || isInt && parseInt(a[columnName]) > parseInt(b[columnName])) {
        return -1;
      }
      if (!isInt && a[columnName] < b[columnName] || isInt && parseInt(a[columnName]) < parseInt(b[columnName])) {
        return 1;
      }
      return 0;
    });
  }
  return [...val];
};
export const toString = val => {
  return (val ? val : "").toString();
};
export const SumBy = (arr, func) => {
  if (arr) {
    return arr.reduce((acc, item) => acc + func(item), 0);
  }
  return 0;
};
export const Sum = function (items, collumnName, isFloat) {
  if (isFloat === void 0) {
    isFloat = false;
  }
  let total = 0;
  if (items && items.length) {
    total = items.reduce((prev, cur) => {
      if (isFloat) {
        return (!prev || isNaN(prev) ? 0 : parseFloat(prev, 10)) + (!cur[collumnName] || isNaN(cur[collumnName]) ? 0 : parseFloat(cur[collumnName], 10));
      } else {
        return (!prev || isNaN(prev) ? 0 : parseInt(prev, 10)) + (!cur[collumnName] || isNaN(cur[collumnName]) ? 0 : parseInt(cur[collumnName], 10));
      }
    }, 0);
  }
  return total;
};
export const Nonce = length => {
  let text = [];
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i++) {
    text.push(possible.charAt(Math.floor(Math.random() * possible.length)));
  }
  return text.join("");
};
export const Trim = function (val, valToTrim) {
  if (valToTrim === void 0) {
    valToTrim = " ";
  }
  if (val) {
    val = val.split(valToTrim).filter(e => {
      return e ? true : false;
    }).map(itm => itm.trim()).join(valToTrim);
  }
  return val;
};
export const StringFormatPlaceHolder = (val, placeholders) => {
  if (val) {
    let results = val;
    for (const propertyName in placeholders) {
      const re = new RegExp("{" + propertyName + "}", "gm");
      results = results.replace(re, placeholders[propertyName]);
    }
    return results;
  }
  return val;
};
export const Clone = function (obj, defaultError) {
  if (defaultError === void 0) {
    defaultError = null;
  }
  try {
    return JSON.parse(JSON.stringify(obj));
  } catch (e) {
    return defaultError;
  }
};
export const upperCase = val => {
  return (val ? val : "").toUpperCase();
};
export const HasProperty = (events, key) => {
  if (Object.prototype.hasOwnProperty.call(events, key)) {
    return true;
  }
  return false;
};
export const StringFormat = function (val) {
  for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    args[_key - 1] = arguments[_key];
  }
  return val.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] !== "undefined" ? args[number] : match;
  });
};
export const JobPostSkills = (list, isRequired) => {
  return list ? JSON.parse(list).filter(item => item.CategoryId === (isRequired ? 55 : 56)).map(item => item.SkillName).join(", ") : "";
};
export const SendToGoogleAnalytics = _ref => {
  let {
    name,
    delta,
    value,
    id
  } = _ref;
  // Assumes the global `gtag()` function exists, see:
  // https://developers.google.com/analytics/devguides/collection/ga4
  window.gtag("event", name, {
    value: delta,
    // Use `delta` so the value can be summed.
    metric_id: id,
    // Needed to aggregate events.
    metric_value: value,
    // Optional.
    metric_delta: delta // Optional.
  });
  console.log({
    name,
    delta,
    value,
    id
  });
};
export const CleanUrl = paramUrl => {
  if (paramUrl.includes("#google_vignette")) {
    paramUrl = paramUrl.split("#google_vignette")[0];
  }
  if (paramUrl.includes("&shareid=")) {
    paramUrl = paramUrl.split("&shareid=")[0];
  }
  if (paramUrl.includes("?shareid=")) {
    paramUrl = paramUrl.split("?shareid=")[0];
  }
  return paramUrl;
};
export const ConvertQueryStringToJson = param => {
  let result = {};
  param.split("&").forEach(pair => {
    pair = pair.split("=");
    result[pair[0]] = pair[1] || "";
  });
  return result;
};
export const Waiter = async function (seconds) {
  if (seconds === void 0) {
    seconds = 2000;
  }
  if (seconds) {
    await new Promise(resolve => setTimeout(resolve, seconds));
  }
};
export const GetParameterByName = function (name, isExact) {
  if (isExact === void 0) {
    isExact = false;
  }
  let url = GetState(CFW_CURRENT_URL, "");
  url = isExact ? url : url.toLowerCase();
  name = isExact ? name.replace(/[\[\]]/g, "\\$&") : name.replace(/[\[\]]/g, "\\$&").toLowerCase();
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return "";
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};
export const GetShareId = function (adSlot) {
  if (adSlot === void 0) {
    adSlot = null;
  }
  let shareId = GetParameterByName("shareid");
  const adSlot1 = GetDefaultAdsenseId(3);
  if (!shareId || shareId.split("_").length !== 2) {
    return [adSlot || adSlot1, adSlot || adSlot1];
  }
  shareId = shareId.split("_");
  shareId[0] = shareId[0] || adSlot || adSlot1;
  shareId[1] = shareId[1] || adSlot || adSlot1;
  return shareId;
};
export const MergeObjects = function () {
  for (var _len2 = arguments.length, arr = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    arr[_key2] = arguments[_key2];
  }
  return arr.reduce((acc, val) => {
    return {
      ...acc,
      ...val
    };
  }, {});
};
export const MergeUniqueStateData = (tempData, stateName, columnToCompare, filterState) => {
  const list = [...GetState(stateName, [])];
  if (list.length) {
    tempData = !tempData ? [] : tempData;
    list.forEach(itm => {
      if ([...tempData].filter(i => {
        return i[columnToCompare] === itm[columnToCompare];
      }).length <= 0) {
        tempData.push(itm);
      }
    });
  }
  UpdateStateField(filterState, "EndOfList", list.length ? false : true);
  SetState(stateName, [...tempData]);
};
export const GetSelectedCountry = () => {
  return GetState(CFW_DROPDOWN_COUNTRY) ?? GetVisitorCountry()?.id ?? User?.CountryId();
};
export const SetSelectedCountry = data => {
  Cookies.remove(CFW_CLIENT_COUNTRY);
  Cookies.set(CFW_CLIENT_COUNTRY, Encrypt(JSON.stringify(data)), {
    path: "/",
    httpOnly: false,
    secure: true,
    maxAge: 315360000,
    sameSite: "strict"
  });
};
export const GetVisitorCountry = function (isFromCurrentLocation) {
  if (isFromCurrentLocation === void 0) {
    isFromCurrentLocation = false;
  }
  if (typeof window !== "undefined" && navigator.geolocation && !User.SelectedCountry() && isFromCurrentLocation) {
    navigator.geolocation.getCurrentPosition(position => {
      GetLocationFromLatLong(position.coords.latitude, position.coords.longitude).then(results => {
        SetSelectedCountry(results.country);
        window.location.reload(true);
      });
    });
  }
  let country = User.SelectedCountry();
  if (country && !country?.id) {
    country = JSON.parse(country);
    const countries = GetCountries(GetState(Response_CountryData));
    const selectedCountry = countries.find(item => item.Value.toLowerCase().includes(country.name.toLowerCase()));
    country.id = selectedCountry ? selectedCountry.Key : User?.CountryId() ?? null;
    SetSelectedCountry(country);
  }
  return User.SelectedCountry();
};
export const IsTrue = val => {
  if (typeof val === "boolean") {
    return val;
  } else if (typeof val === "string") {
    return val.toLowerCase() === "true" || val.toLowerCase() === "1";
  } else if (typeof val === "number") {
    return val === 1;
  }
  return false;
};
export const StripHtml = html => {
  return html.replace(/( |<([^>]+)>)/gi, " ").replace(/([a-z])([A-Z])/g, "$1 $2").replace(/&nbsp;/g, " ").replace(/[\n\r]+/g, " ").replace(/ {2}/g, " ").replace(/Ur Tech City/g, "UrTechCity").replace(/UPublish Me/g, "UPublishMe").replace(/Ur Tech POGO/g, "UrTechPOGO").replace(/Ur Tech Shop/g, "UrTechShop").replace(/Ur Tech POS/g, "UrTechPOS").trim();
};
export const OnlyAlphaNumeric = val => {
  return val.replace(/[^a-z A-Z 0-9 \. ,]+/gi, "");
};
export const ConvertJsonToQueryString = (jsonVal, qStrings) => {
  return qStrings.split(",").filter(itm => HasProperty(jsonVal, itm) && jsonVal[itm]).map(itm => {
    return `${itm.toString()}=${jsonVal[itm]}`;
  }).join("&");
};
export const IsMobile = () => {
  try {
    return window.matchMedia("only screen and (max-width: 699px)").matches ? "1" : "0";
  } catch {
    return "0";
  }
};
export const GetFormattedDate = function (dateParam) {
  if (dateParam === void 0) {
    dateParam = new Date();
  }
  dateParam = new Date(dateParam);
  const currentDate = `${dateParam.getDate() + 1}-${GetMonthName(dateParam.getMonth() + 1, true)}-${dateParam.getFullYear()}`;
  return currentDate;
};
export const GetDataNotHave = (data, colName, value) => {
  if (!data) {
    return null;
  } else if (!value) {
    return data;
  } else {
    return [...data].filter(itm => {
      return (itm[colName] || "").toString() !== (value || "").toString();
    });
  }
};
export const GetFirstData = (stateName, colName, value) => {
  const param = typeof stateName === "string" ? GetState(stateName) : stateName;
  if (!param) {
    return null;
  } else if (!value) {
    return param[0];
  } else {
    const data = [...param].filter(itm => {
      return (itm[colName] || "").toString() === (value || "").toString();
    });
    if (!data) {
      return data;
    } else {
      return data[0];
    }
  }
};
export const ConstructPath = function (languageCode, productCode, page, noOrigin) {
  if (noOrigin === void 0) {
    noOrigin = false;
  }
  const results = `${noOrigin ? "" : UI_URL}/${productCode.toLowerCase()}/${languageCode}/${page}`;
  return results;
};
export const GetHashTags = function (numberOfTags) {
  if (numberOfTags === void 0) {
    numberOfTags = 1;
  }
  let hashtag = GetStateField(Response_SiteMapUrlData, "Tags", "").trim().split(",").filter(item => !!item);
  hashtag = hashtag?.length ? [...hashtag].map(item => `#${item}`.replace(/ /g, "")) : null;
  if (hashtag) {
    if (numberOfTags === 1) {
      hashtag = hashtag[GetRandomInt(hashtag?.length)];
    } else {
      hashtag = hashtag.splice(0, Math.min(numberOfTags, hashtag.length - 1));
    }
  }
  SetState(CFW_GENERATED_TAGS, hashtag);
  return hashtag;
};
export const ExtractItemState = (state, colName, value) => {
  let list = GetState(state, []);
  const currentItem = GetFirstState(list, colName, value);
  if (list.length && currentItem) {
    list = list.filter(item => item[colName] !== currentItem[colName]);
  }
  return {
    currentList: list,
    currentItem
  };
};
export const GetFirstState = (state, colName, value) => {
  if (!state) {
    return null;
  } else if (!value) {
    const currentState = typeof state === "string" ? GetState(state) : state;
    return currentState?.length ? currentState[0] : null;
  } else {
    const currentState = typeof state === "string" ? GetState(state) : state;
    const data = [...currentState].filter(itm => {
      return (itm[colName] || "").toString() === (value || "").toString();
    });
    if (!data) {
      return data;
    } else {
      return data[0];
    }
  }
};
export const GetCountries = countries => {
  const coll = [];
  if (countries) {
    countries.forEach(itm => {
      coll.push({
        Key: itm.CountryId,
        Code: itm.CountryCode,
        CountryName: itm.CountryName,
        Value: `${itm.CountryName} - ${itm.PaypalCurrency}(${itm.CurrencySymbol})`,
        Sort: itm.CountryId,
        OptionGroup: itm.ContinentName
      });
    });
  }
  return Sort(coll, "Sort", "asc");
};
export const GetStates = (countryId, tempData) => {
  const coll = [];
  if (tempData?.length) {
    tempData.filter(item => item.CountryId === parseInt(countryId, 0)).forEach(itm => {
      coll.push({
        Key: itm.StateId,
        Value: itm.StateName,
        Sort: itm.StateName
      });
    });
  }
  return Sort(coll, "Sort", "asc");
};
export const GetStateDictionary = (state, keyName, valName) => {
  let coll = [];
  if (state) {
    [...state].forEach(itm => {
      coll.push({
        Key: itm[keyName],
        Value: itm[valName]
      });
    });
  }
  return Sort(coll, "Value", "asc");
};
export const GetArrayDictionary = splitString => {
  let coll = [];
  if (Array.isArray(splitString)) {
    if (splitString) {
      splitString.forEach(itm => {
        coll.push({
          Key: itm,
          Value: itm
        });
      });
    }
    return Sort(coll, "Value", "asc");
  } else {
    if (splitString.split(",")) {
      [...splitString.split(",")].forEach(itm => {
        coll.push({
          Key: itm,
          Value: itm
        });
      });
    }
    return Sort(coll, "Value", "asc");
  }
};
export const GetMonthName = function (val, isShort) {
  if (isShort === void 0) {
    isShort = false;
  }
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  if (isShort) {
    return months[val - 1].substring(0, 3);
  } else {
    return months[val - 1];
  }
};
export const GetProductAndLanguage = pathname => {
  return {
    languageCode: CurrentLanguageCode(pathname),
    ...GetProductCode(pathname)
  };
};
const CurrentLanguageCode = pathname => {
  try {
    const langParam = pathname.split("/").find(a => a.length === 2);
    let lang = langParam;
    lang = (lang ?? "en").toLowerCase();
    let getLanguageCode = LanguagesData;
    if (getLanguageCode) {
      getLanguageCode = getLanguageCode.find(item => item.LanguageCode === lang);
      lang = getLanguageCode?.LanguageCode ?? "en";
    }
    return lang;
  } catch (e) {
    console.log("error", "lang");
  }
};
export const CurrentLanguage = () => {
  const location = useLocation();
  return CurrentLanguageCode(location.pathname);
};
const GetProductCode = location => {
  try {
    let urlParam = location.includes("?") ? location.split("?")[0] : location;
    urlParam = urlParam.split(UI_URL).filter(item => !!item)[0];
    urlParam = urlParam.toLowerCase().split("/").filter(item => !!item && item !== "#");
    if (location.toLowerCase() === UI_URL.toLowerCase()) {
      console.log(urlParam);
    }
    if (urlParam.includes("urtechcity")) {
      return {
        productCode: "URTECHCITY",
        title: "UrTechCity"
      };
    } else if (urlParam.includes("urtechpogo")) {
      return {
        productCode: "URTECHPOGO",
        title: "UrTechPOGO"
      };
    } else if (urlParam.includes("urtechpos")) {
      return {
        productCode: "URTECHPOS",
        title: "UrTechPOS"
      };
    } else if (urlParam.includes("upublishme")) {
      return {
        productCode: "UPUBLISHME",
        title: "UPublishMe"
      };
    } else if (urlParam.includes("urtechshop")) {
      return {
        productCode: "URTECHSHOP",
        title: "UrTechShop"
      };
    } else {
      return {
        productCode: "JOBPORTAL",
        title: "JobPortal"
      };
    }
  } catch (e) {
    console.log("error", "GetProductCode");
  }
};
export const RemoveElement = elementId => {
  const ctl = document.getElementById(elementId);
  if (ctl) {
    ctl.remove();
  }
};
export const AddScriptsWithCallBack = function (url, scriptId, customAttributes, callBack, innerHTML, insertBeforeAnyScripts, noDefaultAttributes) {
  if (innerHTML === void 0) {
    innerHTML = null;
  }
  if (insertBeforeAnyScripts === void 0) {
    insertBeforeAnyScripts = false;
  }
  if (noDefaultAttributes === void 0) {
    noDefaultAttributes = false;
  }
  const isScriptTagExists = document.getElementById(scriptId);
  const script = document.createElement("script");
  if (!isScriptTagExists) {
    if (url) {
      script.src = url;
    }
    script.id = scriptId;
    if (!noDefaultAttributes) {
      script.async = true;
      script.setAttribute("nonce", btoa(Date.now().toString()));
    }
    if (innerHTML) {
      script.innerHTML = innerHTML;
    }
    if (customAttributes) {
      Object.entries(customAttributes).forEach(itm => {
        script.setAttribute(itm[0], itm[1]);
      });
    }
    if (callBack) {
      const loader = () => {
        callBack();
        script.removeEventListener("load", loader);
      };
      if (url) {
        script.addEventListener("load", loader, {
          passive: true
        });
      } else {
        callBack(false);
      }
    }
    if (insertBeforeAnyScripts) {
      const s0 = document.getElementsByTagName("script")[0];
      s0.parentNode.insertBefore(script, s0);
    } else {
      document.head.append(script);
    }
  } else {
    if (callBack) {
      callBack(true);
    }
  }
};
export const AddStyleSheetWithCallBack = (url, callBack) => {
  if (typeof window === "undefined") {
    if (callBack) {
      callBack();
    }
    return;
  }
  const scriptId = GenerateNameFromUrl(url);
  const isScriptTagExists = document.getElementById(scriptId);
  const script = document.createElement("link");
  if (!isScriptTagExists) {
    script.href = url;
    script.id = scriptId;
    script.setAttribute("rel", "stylesheet");
    if (callBack) {
      if (url) {
        const loader = () => {
          callBack();
          script.removeEventListener("load", loader);
        };
        script.addEventListener("load", loader, {
          passive: true
        });
      } else {
        callBack();
      }
    }
    document.head.append(script);
  } else {
    if (callBack) {
      callBack();
    }
  }
};
export const GetKeyValues = function (state, keyName, filterName, filterValue) {
  if (filterName === void 0) {
    filterName = "";
  }
  if (filterValue === void 0) {
    filterValue = "";
  }
  let coll = [];
  let data = GetState(state);
  if (data) {
    if (filterName && filterValue) {
      data = [...data].filter(itm => {
        return (itm[filterName] || "").toString() === (filterValue || "").toString();
      });
    }
    [...data].forEach(itm => {
      coll.push(itm[keyName]);
    });
  }
  return coll;
};
export const GetDictionaryStorage = function (tempData, keyName, valName, filterName, filterValue) {
  if (filterName === void 0) {
    filterName = "";
  }
  if (filterValue === void 0) {
    filterValue = "";
  }
  let coll = [];
  if (tempData) {
    if (filterName && filterValue) {
      tempData = [...tempData].filter(itm => {
        return (itm[filterName] || "").toString() === (filterValue || "").toString();
      });
    }
    [...tempData].forEach(itm => {
      coll.push({
        Key: itm[keyName],
        Value: itm[valName]
      });
    });
  }
  return Sort(coll, "Value", "asc");
};
export const GetDictionaryState = function (state, keyName, valName, filterName, filterValue) {
  if (filterName === void 0) {
    filterName = "";
  }
  if (filterValue === void 0) {
    filterValue = "";
  }
  let coll = [];
  let data = GetState(state);
  if (data) {
    if (filterName && filterValue) {
      data = [...data].filter(itm => {
        return (itm[filterName] || "").toString() === (filterValue || "").toString();
      });
    }
    [...data].forEach(itm => {
      coll.push({
        Key: itm[keyName],
        Value: itm[valName]
      });
    });
  }
  return Sort(coll, "Value", "asc");
};
export const GetDynamicOptions = (typeId, languageCode) => {
  let coll = [];
  const dynamicOptionsList = GetState(Response_DynamicOptionsDropDownData, DynamicOptionsData);
  if (dynamicOptionsList) {
    let lst = [...dynamicOptionsList].filter(itm => itm.LanguageCode === languageCode);
    lst.forEach(itm => {
      if (itm.DynamicOptionTypeId === typeId) {
        coll.push({
          Key: itm.DynamicOptionsId,
          Value: itm.DynamicOptionsName,
          Sort: itm.SortId,
          Description: itm.Description
        });
      }
    });
  }
  return Sort(coll, "Sort", "asc");
};
export const GetTextFromHTMLString = content => {
  return content.replace(/<(.|\n)*?>/g, "");
};
export const GetMatchedValuesFromRegEx = function (regexPattern, findFromContent, isMultiple, defaultVal) {
  if (findFromContent === void 0) {
    findFromContent = "";
  }
  if (isMultiple === void 0) {
    isMultiple = false;
  }
  if (defaultVal === void 0) {
    defaultVal = "";
  }
  let results = findFromContent.match(regexPattern, "gmi");
  let contents = findFromContent;
  if (results) {
    if (isMultiple) {
      const resultsArray = [];
      while (results) {
        resultsArray.push(results[0]);
        contents = contents.replace(results[0], "");
        results = contents.match(regexPattern, "gmi");
      }
      return resultsArray;
    } else {
      return results[0];
    }
  }
  if (isMultiple) {
    return [defaultVal];
  } else {
    return defaultVal;
  }
};
export const GenerateUrl = (name, id) => {
  return `${(name ?? "").replace(/ {2}/g, " ").replace(/-/g, "").replace(/ /g, "_").replace(/\//g, "_").replace(/\W/g, "")}-${id}`;
};
export const GetOptions = typeId => {
  let coll = [];
  const optionList = OptionsData;
  if (optionList) {
    [...optionList].forEach(itm => {
      if (itm.OptionTypeId === typeId) {
        coll.push({
          Key: itm.OptionsId,
          Value: itm.OptionsName,
          Sort: itm.SortId
        });
      }
    });
  }
  return Sort(coll, "Sort", "asc");
};
export const Sorter = (data, ctl) => {
  [...ctl.parentElement.children].forEach(itm => {
    if (itm !== ctl && itm.hasAttribute("order")) {
      itm.setAttribute("order", "");
    }
  });
  ctl.setAttribute("order", ctl.getAttribute("order") === "asc" ? "desc" : "asc");
  return Sort(data, ctl.getAttribute("colname"), ctl.getAttribute("order"));
};
export const ShortVal = (val, maxVal) => {
  val = Trim(val);
  if (val && val.length >= maxVal) {
    return `${val.substring(0, maxVal - 3)}...`;
  }
  return val;
};
export const IsImageUrlExists = async url => {
  return new Promise(resolve => {
    const img = new Image();
    img.src = url;
    if (img.complete) {
      resolve(true);
    } else {
      img.onload = () => {
        resolve(true);
      };
      img.onerror = () => {
        resolve(false);
      };
    }
  });
};
export const CommaFormatDigit = function (val, hasDecimal, defaultValue) {
  if (hasDecimal === void 0) {
    hasDecimal = true;
  }
  if (defaultValue === void 0) {
    defaultValue = "";
  }
  if (!val) {
    return defaultValue;
  }
  if (hasDecimal) {
    val = parseFloat(val.toString()).toFixed(2);
  } else {
    val = parseFloat(val.toString());
  }
  return val.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
};
export const CollectHashTags = () => {
  let tags = GetStateField(Response_SiteMapUrlData, "Title").replace(/ {2}/g, " ").replace(/ /g, ",").replace(/,,/g, ",").split(",");
  tags = tags.filter(item => item.length > 3).join(",");
  return tags;
};