export const BANNER_CURRENT_INDEX = "BANNER_CURRENT_INDEX";
export const UPDATESITEMAP = "UPDATESITEMAP";
export const CFW_CURRENTSTATE = "CURRENTSTATE";
export const CFW_CLIENTID = "CLIENTID";
export const CFW_JOBPOSTID = "JOBPOSTID";
export const CFW_IS_MOBILE = "IS_MOBILE";
export const CFW_SELECTEDVALUES = "SELECTEDVALUES";
export const CFW_HANDLE_SEO_PROCESS = "HANDLE_SEO_PROCESS";
export const CFW_SCROLL_COUNTER = "SCROLL_COUNTER";
export const CFW_SCROLL_COUNTER_PROCESSED = "SCROLL_COUNTER_PROCESSED";
export const CFW_LINKED_IN_ACCESS_TOKEN = "LINKED_IN_ACCESS_TOKEN";
export const CFW_CURRENT_ATTACHMENT_LIST_NAME = "CURRENT_ATTACHMENT_LIST_NAME";
export const CFW_GENERATED_TAGS = "GENERATED_TAGS";
export const CFW_LINKEDIN_DATA = "LINKEDIN_DATA";
export const CFW_LINKEDIN_PROCESSED = "LINKEDIN_PROCESSED";
export const CFW_LINKEDIN_AUTH_RESULT = "CFW_LINKEDIN_AUTH_RESULT";
export const CFW_PAGENAME = "PAGENAME";
export const CFW_UPLOAD_GROUP_ID = "UPLOAD_GROUP_ID";
export const CFW_PROCESS_CHILD_ID = "PROCESS_CHILD_ID";
export const CFW_CLIENT_COUNTRY = "CLIENT_COUNTRY";
export const CFW_ADSENSE_READY = "ADSENSE_READY";
export const CFW_CLIENT_COUNTRY_DECLINED = "CFW_CLIENT_COUNTRY_DECLINED";
export const CFW_DROPDOWN_COUNTRY = "DROPDOWN_COUNTRY";
export const CFW_LOAD_MENU = "LOAD_MENU";
export const CFW_TOTAL_CASH = "TOTAL_CASH";
export const CFW_TOTAL_STATE = "TOTAL_STATE";
export const CFW_DROPDOWN_CATEGORY = "DROPDOWN_CATEGORY";
export const CFW_DROPDOWN_LANGUAGE = "DROPDOWN_LANGUAGE";
export const CFW_DROPDOWN_STATE = "DROPDOWN_STATE";
export const CFW_SEARCH = "SEARCH";
export const CFW_FullName = "FullName";
export const CFW_PUBLISHERID = "PublisherId";
export const CFW_PARENTPUBLISHERID = "ParentPublisherId";
export const CFW_ACCESSTOKEN = "AccessToken";
export const CFW_ADDTOCART = "AddToCart";
export const CFW_LOTBETDRAFT = "LotBetDraft";
export const CFW_ROLES = "Roles";
export const CFW_DELIVERYDATA = "DELIVERYDATA";
export const CFW_TEMPATTACHMENTS = "TEMPATTACHMENTS";
export const CFW_EMAIL = "Email";
export const CFW_CONTACT_NUMBER = "ContactNumber";
export const CFW_FACEBOOKID = "FacebookId";
export const CFW_LINKEDINID = "LinkedInId";
export const CFW_GOOGLEID = "GoogleId";
export const CFW_POINTS = "Points";
export const CFW_LOGINFO = "urtechcity_user";
export const CFW_SOCIALLINKING = "SOCIALLINKING";
export const CFW_SOCIAL_INTEGRATION = "SOCIAL_INTEGRATION";
export const CFW_TEMPORARYUSERNAME = "TEMPORARYUSERNAME";
export const CFW_TEMPORARYPICTURE = "TEMPORARYPICTURE";
export const CFW_TWITTERID = "TwitterId";
export const CFW_USERGUID = "UserGUID";
export const CFW_CountryId = "CountryId";
export const CFW_STATE = "STATE";
export const CFW_CODE = "code";
export const CFW_ERROR = "ERROR";
export const CFW_NONCE = "NONCE";
export const CFW_OAUTH_TOKEN = "oauth_token";
export const CFW_OAUTH_TOKEN_SECRET = "OAUTH_TOKEN_SECRET";
export const CFW_TIMESTAMP = "TIMESTAMP";
export const CFW_CURRENT_URL = "CURRENT_URL";
export const PROCESS_ADD = "ADD";
export const PROCESS_ACCEPT = "ACCEPT";
export const PROCESS_UPDATE = "UPDATE";
export const PROCESS_INQUIREPROPOSAL = "INQUIREPROPOSAL";
export const PROCESS_SELECTMESSAGECOUNT = "SELECTMESSAGECOUNT";
export const PROCESS_ADDDETAILS = "ADDDETAILS";
export const PROCESS_EDITDETAILS = "EDITDETAILS";
export const PROCESS_DELETEDETAILS = "DELETEDETAILS";
export const PROCESS_RETRIEVEEMAILS = "RETRIEVEEMAILS";
export const PROCESS_ADDMESSAGE = "ADDMESSAGE";
export const PROCESS_EDITALL = "EDITALL";
export const PROCESS_TASKS = "TASKS";
export const PROCESS_VIEWJOBPOST = "VIEWJOBPOST";
export const PROCESS_UPDATESTATUS = "UPDATESTATUS";
export const PROCESS_CHANGESTATUS = "CHANGESTATUS";
export const PROCESS_EMPLOYERMONTHLYTASKS = "EMPLOYERMONTHLYTASKS";
export const PROCESS_ADDTOQUEUE = "ADDTOQUEUE";
export const PROCESS_DELETEAPPLICATION = "DELETEAPPLICATION";
export const PROCESS_CHANGEPASSWORD = "CHANGEPASSWORD";
export const PROCESS_DELETE = "DELETE";
export const PROCESS_EXCLUDE = "EXCLUDE";
export const PROCESS_UPDATEPOINTS = "UPDATEPOINTS";
export const PROCESS_EDIT = "EDIT";
export const PROCESS_GETTOTALS = "GETTOTALS";
export const PROCESS_ISEXISTS = "ISEXISTS";
export const PROCESS_LOG = "LOG";
export const PROCESS_SELECTGROUP = "SELECTGROUP";
export const PROCESS_LOGIN = "LOGIN";
export const PROCESS_LOGINSOCIAL = "LOGINSOCIAL";
export const PROCESS_RESETPASSWORD = "RESETPASSWORD";
export const PROCESS_MAKEOFFER = "MAKEOFFER";
export const PROCESS_SELECT = "SELECT";
export const PROCESS_SELECT_NAME = "SELECTNAME";
export const PROCESS_SELECT_LOTTERY = "SELECT_LOTTERY";
export const PROCESS_SELECT_REFERALS = "SELECT_REFERALS";
export const PROCESS_ADDADUNIT = "ADDADUNIT";
export const PROCESS_SELECTCOMMENTS = "SELECTCOMMENTS";
export const PROCESS_SELECTAVAILABLEITEMS = "SELECTAVAILABLEITEMS";
export const PROCESS_SELECTAVAILABLEPACKAGES = "SELECTAVAILABLEPACKAGES";
export const PROCESS_VIEWOFFER = "VIEWOFFER";
export const PROCESS_SELECTMESSAGE = "SELECTMESSAGE";
export const PROCESS_UPDATEROLESTATUS = "UPDATEROLESTATUS";
export const PROCESS_SELECTTRANSACTION = "SELECTTRANSACTION";
export const PROCESS_SELECTPAYMENTS = "SELECTPAYMENTS";
export const PROCESS_EDITPUBLISHERID = "EDITPUBLISHERID";
export const PROCESS_UPDATEADSENSE = "UPDATEADSENSE";
export const PROCESS_ADDTRANSACTION = "ADDTRANSACTION";
export const PROCESS_EDITTRANSACTION = "EDITTRANSACTION";
export const PROCESS_DELETETRANSACTION = "DELETETRANSACTION";
export const PROCESS_SELECTITEMTYPE = "SELECTITEMTYPE";
export const PROCESS_SELECTITEMNAME = "SELECTITEMNAME";
export const PROCESS_SELECTDATE = "SELECTDATE";
export const PROCESS_ADDINVENTORY = "ADDINVENTORY";
export const PROCESS_ADDPACKAGE = "ADDPACKAGE";
export const PROCESS_ADDSALES = "ADDSALES";
export const PROCESS_VIEWCONTENT = "VIEWCONTENT";
export const PROCESS_REQUESTPAYMENT = "REQUESTPAYMENT";
export const CFW_SHARE_TAGS = "TAGS";
export const CFW_MARQUEE_TEXT = "MARQUEE_TEXT";
export const CFW_CURRENTNUMBER = "CFW_CURRENTNUMBER";
export const CFW_ACTIVATE_SOCIAL_BUTTONS = "ACTIVATE_SOCIAL_BUTTONS";
export const CFW_REFRESHER = "CFW_REFRESHER";
export const CFW_BANNER_REFRESHER = "CFW_BANNER_REFRESHER";
export const REGEXP_EMAIL = "([A-Za-z0-9][._]?)+[A-Za-z0-9]@[A-Za-z0-9]+(.?[A-Za-z0-9]){2}.([A-Za-z0-9]{3,4})+(.[A-Za-z0-9]{2,4})?";
export const REGEXP_PASSWORD = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";
export const REGEXP_NUMBER = "^[0-9]*$";
export const REGEXP_DECIMAL = "([0-9]+.)?[0-9]+";
export const REGEXP_URL = "(\b(https?|ftp|file)://)?[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]";
export const REGEXP_IMAGEURL = "https?:[^)'\"]+.(?:jpg|jpeg|gif|png)";
export const REGEXP_REMOVETAGS = /(<([^>]+)>)/gi;
export const Role_SuperAdmin = 15;
export const Role_Admin = 16;
export const Role_CompanyOwner = 17;
export const Role_Agency = 18;
export const Role_NormalUser = 19;
export const Role_TeamLeader = 20;
export const Role_Translator = 21;
export const Role_Driver = 39;
export const Role_OpratorOrMarketer = 40;
export const Role_Reseller = 41;
export const Response_ViewedSharesData = "ViewedSharesData";
export const Response_HashData = "HashData";
export const Response_BannerData = "BannerData";
export const Response_StatesData = "StatesData";
export const Response_CountryData = "CountryData";
export const Response_SendEmailData = "SendEmailData";
export const Response_UploadData = "UploadData";
export const Response_GenerateDContentData = "GenerateDContentData";
export const Response_OurServicesList = "OurServicesList";
export const Response_OurService = "OurService";
export const Response_TotalUserCount = "TotalUserCount";
export const Response_LotteryGameListData = "LotteryGameListData";
export const Response_LotGamesData = "LotGamesData";
export const Response_LotPaymentsData = "LotPaymentsData";
export const Response_PointsData = " PointsData";
export const Response_LotDrawsData = "LotDrawsData";
export const Response_MessagesData = "MessagesData";
export const Response_MessagesHeaderData = "MessagesHeaderData";
export const Response_MessagesCount = "MessagesCount";
export const Response_EmployeeTaskData = "EmployeeTaskData";
export const Response_ProductData = "ProductData";
export const Response_SearchShopsData = "SearchShopsData";
export const Response_ClientsData = "ClientsData";
export const Response_RecommendData = "RecommendData";
export const Response_GroupsData = "GroupsData";
export const Response_SiteMapUrlData = "SiteMapUrlData";
export const Response_RecommendUsData = "RecommendUsData";
export const Response_ConfirmPaymentData = "ConfirmPaymentData";
export const Response_OrderHeaderData = "OrderHeaderData";
export const Response_OrderDetailData = "OrderDetailData";
export const Response_ViewResellerPackageItemData = "ViewResellerPackageItemData";
export const Response_ViewResellerCommentsData = "ViewResellerCommentsData";
export const Response_ResellerPackageItemsData = "ResellerPackageItemsData";
export const Response_ResellerPackageItemsLookUpData = "ResellerPackageItemsLookUpData";
export const Response_OurPeopleData = "OurPeopleData";
export const Response_ResellerPackageItemDetailsData = "ResellerPackageItemDetailsData";
export const Response_ViewBusinessProfileData = "ViewBusinessProfileData";
export const Response_ViewBusinessProfileFilesData = "ViewBusinessProfileFilesData";
export const Response_ViewJobPostsData = "ViewJobPostsData";
export const Response_ViewJobPostsFilesData = "ViewJobPostsFilesData";
export const Response_DynamicOptionTypeData = "DynamicOptionTypeData";
export const Response_DynamicOptionsData = "DynamicOptionsData";
export const Response_ViewResellerPackageItemDetailData = "ViewResellerPackageItemDetailData";
export const Response_DynamicOptionsDropDownData = "DynamicOptionsDropDownData";
export const Response_TaskData = "TaskData";
export const Response_OurServicesData = "OurServicesData";
export const Response_LoginData = "LoginData";
export const Response_UsersData = "UsersData";
export const Response_AdsenseEarnings = "AdsenseEarnings";
export const Response_AdsenseEarningsReferal = "AdsenseEarningsReferal";
export const Response_TranslationData = "TranslationData";
export const Response_TranslationTypeData = "TranslationTypeData";
export const Response_SelectedTranslation = "SelectedLanguage";
export const Response_TranslationItem = "TranslationItem";
export const Response_TranslateFromEnglishOpenAI = "TranslateFromEnglishOpenAI";
export const Response_SiteMapData = "SiteMapData";
export const Response_HandleSEOSiteMapData = "HandleSEOSiteMapData";
export const Response_GenerateSiteMapData = "GenerateSiteMapData";
export const Response_EmailBlasterData = "EmailBlasterData";
export const Response_EmailBlasterContentData = "EmailBlasterContentData";
export const Response_PositionData = "PositionData";
export const Response_EmailAddressData = "EmailAddressData";
export const Response_EmailList = "EmailList";
export const Response_ClientProfileData = "ClientProfileData";
export const Response_ClientProfileLotteryData = "ClientProfileLotteryData";
export const Response_ClientProfileFilesData = "ClientProfileFilesData";
export const Response_FileDowloadData = "FileDowloadData";
export const Response_ItemDetailsData = "ItemDetailsData";
export const Response_EmployeesData = "EmployeesData";
export const Response_SuppliersData = "SuppliersData";
export const Response_LotBetsData = "LotBetsData";
export const Response_LotOrderData = "LotOrderData";
export const Response_ItemsData = "ItemsData";
export const Response_InventoryData = "InventoryData";
export const Response_PackagesData = "PackagesData";
export const Response_DetailData = "DetailData";
export const Response_ExpensesData = "ExpensesData";
export const Response_PaymentsData = "PaymentsData";
export const Response_UserPaymentsData = "UserPaymentsData";
export const Response_SalesData = "SalesData";
export const Response_POSData = "POSData";
export const Response_OrderData = "OrderData";
export const Response_CartData = "CartData";
export const Response_CartCount = "CartCount";
export const Response_ViewOrderData = "ViewOrderData";
export const Response_ContentData = "ContentData";
export const Response_FindProfileData = "FindProfileData";
export const Response_HTMLContentData = "HTMLContentData";
export const Response_ContentViewData = "ContentViewData";
export const Response_JobProfileData = "JobProfileData";
export const Response_JobProfileFilesData = "JobProfileFilesData";
export const Response_SkillsData = "SkillsData";
export const Response_AllSkillsData = "AllSkillsData";
export const Response_UserLanguageData = "UserLanguageData";
export const Response_PortfolioData = "PortfolioData";
export const Response_PortfolioFilesData = "PortfolioFilesData";
export const Response_OtherCredentialData = "OtherCredentialData";
export const Response_OtherCredentialFilesData = "OtherCredentialFilesData";
export const Response_EducationData = "EducationData";
export const Response_EducationFilesData = "EducationFilesData";
export const Response_WorkExperienceData = "WorkExperienceData";
export const Response_JobPostData = "JobPostData";
export const Response_SpecificJobPostData = "SpecificJobPostData";
export const Response_JobPostsSkillsData = "JobPostsSkillsData";
export const Response_JobPostsFilesData = "JobPostsFilesData";
export const Response_ApplicationProposalData = "ApplicationProposalData";
export const Response_FindJobsData = "FindJobsData";
export const Response_UserTaskData = "UserTaskData";
export const Response_DailyTimeRecordData = "DailyTimeRecordData";
export const PRODUCTCODES_URTECHCITY = "URTECHCITY";
export const PRODUCTCODES_JOBPORTAL = "JOBPORTAL";
export const PRODUCTCODES_UPUBLISHME = "UPUBLISHME";
export const PRODUCTCODES_URTECHPOS = "URTECHPOS";
export const PRODUCTCODES_URTECHSHOP = "URTECHSHOP";
export const PRODUCTCODES_URTECHPOGO = "URTECHPOGO";
export const DIALOGHOLDER = "DIALOGHOLDER";
export const FILTERHOLDER = "FILTERHOLDER";
export const AcceptedFileExtensions = curVal => {
  return `.${curVal.split("|").join(",.")}`;
};
export const FileExtensions_Video = "mkv|flv|vob|ogg|avi|mov|wmv|mpg|m4v|mp4|3gp|ogv";
export const FileExtensions_Sound = "3gp|m4a|m4b|m4p|mp3|raw|wma|webm|wav|ogg";
export const FileExtensions_Image = "jpg|jpeg|tiff|gif|bmp|svg|png|webp|avif";
export const FileExtensions_Document = "html|html|doc|docx|xls|xlsx|ppt|pptx|rtf|odt";
export const FileExtensions_PDF = "pdf";
export const AdsenseScopes = "https://www.googleapis.com/auth/adsense https://www.googleapis.com/auth/adsense.readonly";
export const AdSenseReportURL = "https://content-adsense.googleapis.com/v2/accounts/pub-{companyAdsenseId}/reports:generate?dateRange=CUSTOM&dimensions=AD_UNIT_ID&dimensions=MONTH&endDate.day=31&endDate.month=12&endDate.year={year}&metrics=ESTIMATED_EARNINGS&startDate.day=1&startDate.month=1&startDate.year={year}&key={apiKey}";
export const FileType_Video = 1;
export const FileType_Sound = 3;
export const FileType_Image = 4;
export const FileType_Document = 5;
export const FileType_ProfliePicture = 8;
export const ColorArrays = ["#E57373", "#8BC1F7", "#519DE9", "#06C", "#004B95", "#002F5D", "#BDE2B9", "#7CC674", "#4CB140", "#38812F", "#23511E", "#A2D9D9", "#73C5C5", "#009596", "#005F60", "#003737", "#B2B0EA", "#8481DD", "#5752D1", "#3C3D99", "#2A265F", "#F9E0A2", "#F6D173", "#F4C145", "#F0AB00", "#C58C00", "#F4B678", "#EF9234", "#EC7A08", "#C46100", "#8F4700", "#C9190B", "#A30000", "#7D1007", "#470000", "#2C0000", "#F0F0F0", "#D2D2D2", "#B8BBBE", "#8A8D90", "#6A6E73"];
export const CONTACT_DETAILS = "4209 Rockside Hills Drive, Raleigh, North Carolina, USA<br /><br /><b>{0} #:</b><br />+1(919)-400-9111<br /><b>{1} #:</b><br />+1(919)-675-5887<br /><b>{2}:</b><br />contactus@urtechcity.com";
export const PAY_RATES = [{
  RateType: "HourlyRate",
  OfferRateType: "OfferHourlyRate",
  Tran: 202,
  OfferTran: 215
}, {
  RateType: "DailyRate",
  OfferRateType: "OfferDailyRate",
  Tran: 201,
  OfferTran: 214
}, {
  RateType: "WeeklyRate",
  OfferRateType: "OfferWeeklyRate",
  Tran: 161,
  OfferTran: 216
}, {
  RateType: "MonthlyRate",
  OfferRateType: "OfferMonthlyRate",
  Tran: 160,
  OfferTran: 217
}];