import { PRODUCTCODES_URTECHCITY } from "./CFW.js";
import { Clone, Waiter } from "./Common.js";
import { Encrypt } from "./SFW.js";
let dbPromise;
const keyName = "key";
const storeName = `${PRODUCTCODES_URTECHCITY}-STORE`;
const IndexedDBData = new Map();
const InitializeIndexedDB = (permission, postEvt) => {
  if (!(typeof window !== "undefined") || !("indexedDB" in window)) {
    console.log("IndexedDB not ready");
    return null;
  }
  const openRequest = indexedDB.open(PRODUCTCODES_URTECHCITY, 1);
  openRequest.onupgradeneeded = e => {
    dbPromise = e.target.result;
    if (!dbPromise.objectStoreNames.contains(storeName)) {
      dbPromise.createObjectStore(storeName, {
        keyPath: keyName
      });
    }
  };
  openRequest.onsuccess = e => {
    dbPromise = e.target.result;
    const transaction = dbPromise.transaction(storeName, permission);
    const objectStore = transaction.objectStore(storeName);
    postEvt(objectStore, transaction);
  };
  openRequest.onerror = e => {
    console.log("InitializeIndexedDB=error", e);
  };
};
export const GetAllIndexDB = async () => {
  await new Promise(resolve => {
    InitializeIndexedDB("readwrite", (objectStore, transaction) => {
      objectStore.getAll().onsuccess = event => {
        // eslint-disable-next-line no-unused-expressions
        transaction.complete;
        if (event.target?.result?.length) {
          event.target.result.forEach(item => {
            IndexedDBData.set(item.key, item.value);
          });
          resolve(true);
        }
        resolve(true);
      };
    });
  });
  return Object.fromEntries(IndexedDBData);
};
export const DeleteIndexedDBStore = async () => {
  const databases = (await indexedDB.databases()).filter(item => item.name === PRODUCTCODES_URTECHCITY);
  if (!databases.length) {
    return true;
  }
  await Promise.all(databases.map(async db => {
    return new Promise(resolve => {
      const openRequest = indexedDB.open(db.name, 1);
      openRequest.onsuccess = () => {
        openRequest.result.close();
        indexedDB.deleteDatabase(db.name);
        resolve(true);
      };
      Waiter(2000).then(() => {
        resolve(true);
      });
    });
  }));
  IndexedDBData.clear();
  return true;
};
export const RemoveIndexDB = async key => {
  InitializeIndexedDB("readwrite", (objectStore, transaction) => {
    const encryptedKey = Encrypt(key);
    const results = objectStore.get(encryptedKey, keyName);
    results.onsuccess = () => {
      objectStore.delete(encryptedKey);
      // eslint-disable-next-line no-unused-expressions
      transaction.complete;
      IndexedDBData.delete(encryptedKey);
    };
  });
};
export const GetIndexedDbValue = key => {
  if (!key || typeof key !== "string") {
    return null;
  }
  const encryptedKey = Encrypt(key);
  const currentEntry = IndexedDBData.get(encryptedKey);
  if (currentEntry) {
    if (currentEntry.responseData) {
      if (currentEntry.responseData?.Results) {
        return currentEntry.responseData.Results;
      }
      return currentEntry.responseData;
    }
    return currentEntry;
  }
  return null;
};
export const SetIndexedDb = (key, value) => {
  InitializeIndexedDB("readwrite", (objectStore, transaction) => {
    let respoonse = Clone(value, "error");
    if (respoonse === "error" || !key || typeof key !== "string") {
      return;
    }
    const encryptedKey = Encrypt(key);
    const results = objectStore.get(encryptedKey, keyName);
    // eslint-disable-next-line no-unused-expressions
    transaction.complete;
    if (respoonse?.responseData) {
      respoonse = {
        responseData: respoonse.responseData
      };
    } else {
      console.log("key-->value", `${key}-->${respoonse.responseData}`);
    }
    results.onsuccess = () => {
      UpdateIndexedDb(encryptedKey, respoonse, objectStore, transaction);
    };
    results.onerror = () => {
      InsertIndexedDb(encryptedKey, respoonse, objectStore, transaction);
    };
  });
};
const InsertIndexedDb = (key, value, objectStore, transaction) => {
  const results = objectStore.add({
    key,
    value
  });
  // eslint-disable-next-line no-unused-expressions
  transaction.complete;
  results.onsuccess = () => {
    IndexedDBData.set(key, value);
  };
  results.onerror = e => {
    console.log("InsertIndexedDb-error", e.message);
  };
};
const UpdateIndexedDb = (key, value, objectStore, transaction) => {
  const results = objectStore.put({
    key,
    value
  });
  // eslint-disable-next-line no-unused-expressions
  transaction.complete;
  results.onsuccess = () => {
    IndexedDBData.set(key, value);
  };
  results.onerror = e => {
    console.log("UpdateIndexedDb-error", e.message);
  };
};